<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <span>
            <span class="text-primary desktop-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary desktop-view h6">
              <span
                >Created : {{ activityDetails.created_on | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div class="pb-3 mobile-view">
          <span>
            <span class="text-primary mobile-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span
                >Created :{{ activityDetails.created_on | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div class="mt-2">
          <CRow>
            <CCol md="5">
              <CRow>
                <CCol md="12">
                  <CCard>
                    <CCardBody>
                      <h6 class="text-primary">Interview Panel:</h6>
                      <table width="100%" cellpadding="5">
                        <tbody>
                          <tr>
                            <td>
                              {{
                                activityDetails.initiated_by_user.full_name
                                  | convert2Sentence
                              }}
                            </td>
                            <td>
                              {{
                                activityDetails.initiated_by_user.roles | role
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                activityDetails.return_action_to_user.full_name
                                  | convert2Sentence
                              }}
                            </td>
                            <td>
                              {{
                                activityDetails.return_action_to_user.roles
                                  | role
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div class="mx-3">
                <CRow class="mb-2 mt-2">
                  <CCol xs="2"><h6>Type</h6> </CCol>
                  <CCol xs="10"
                    ><span class="h6 mx-1">:</span
                    >{{ activityDetails.type_of_process | convert2Sentence }}
                  </CCol>
                </CRow>
                <CRow class="mb-2">
                  <CCol xs="2"><h6>Style</h6> </CCol>
                  <CCol xs="10"
                    ><span class="h6 mx-1">:</span
                    ><span v-html="interviewMode"></span>
                    {{ activityDetails.interview_mode | convert2Sentence }}
                  </CCol>
                </CRow>
                <CRow class="mb-2">
                  <CCol xs="2"><h6>Time</h6> </CCol>
                  <CCol xs="10"
                    ><span class="h6 mx-1">:</span
                    >{{ activityDetails.alloted_time | convert2Sentence }}
                  </CCol>
                </CRow>
              </div>
            </CCol>
            <CCol md="6">
              <h6 class="text-primary">Available Slots</h6>
              <ul class="mt-2">
                <li v-for="(slot, index) of slots" :key="index" class="mt-2">
                  {{ slot.date | formatDate }} - {{ slot.start | formatTime }}
                  <small>to</small>
                  {{ slot.end | formatTime }}
                </li>
              </ul>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import m from "moment";
import _ from "lodash";
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    interviewMode() {
      let icon = "";
      switch (this.activityDetails.interview_mode) {
        case "call":
          icon = '<i class="fas fa-phone-alt mr-1"></i>';
          break;
        case "video":
          icon = '<i class="fas fa-video mr-1 text-primary"></i>';
          break;
        case "in_person":
          icon = '<i class="fas fa-user-alt mr-1 text-success"></i>';
          break;
        default:
          icon = "";
      }
      return icon;
    },
    slots() {
      let slots = [];
      if (this.activityDetails?.available_slot1_date) {
        slots = [
          ...slots,
          {
            key: "slot1",
            date: this.formatDate(this.activityDetails?.available_slot1_date),
            start: this.convert2Date(
              this.activityDetails?.available_slot1_date,
              this.activityDetails?.available_slot1_start
            ),
            end: this.convert2Date(
              this.activityDetails?.available_slot1_date,
              this.activityDetails?.available_slot1_end
            ),
          },
        ];
      }
      if (this.activityDetails?.available_slot2_date) {
        slots = [
          ...slots,
          {
            key: "slot2",
            date: this.formatDate(this.activityDetails?.available_slot2_date),
            start: this.convert2Date(
              this.activityDetails?.available_slot2_date,
              this.activityDetails?.available_slot2_start
            ),
            end: this.convert2Date(
              this.activityDetails?.available_slot2_date,
              this.activityDetails?.available_slot2_end
            ),
          },
        ];
      }
      if (this.activityDetails?.available_slot3_date) {
        slots = [
          ...slots,
          {
            key: "slot3",
            date: this.formatDate(this.activityDetails?.available_slot3_date),
            start: this.convert2Date(
              this.activityDetails?.available_slot3_date,
              this.activityDetails?.available_slot3_start
            ),
            end: this.convert2Date(
              this.activityDetails?.available_slot3_date,
              this.activityDetails?.available_slot3_end
            ),
          },
        ];
      }
      return slots;
    },
  },
  methods: {
    convert2Date(date, time) {
      return m(date + " " + time, "YYYY-MM-DD HH:mm:ss");
    },
    formatDate(date) {
      return m(date, "YYYY-MM-DD");
    },
  },
  filters: {
    convert2Sentence(data) {
      return _.startCase(data);
    },
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    role(data) {
      if (data) return data.map((e) => _.startCase(e.role_type)).join();
      return "--";
    },
    formatTime(date) {
      if (date) return m(date).format("hh:mm A");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
