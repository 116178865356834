<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <span>
            <span class="text-primary desktop-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary desktop-view h6">
              <span
                >Created : {{ activityDetails.created_on | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div class="pb-3 mobile-view">
          <span>
            <span class="text-primary mobile-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span
                >Created :{{ activityDetails.created_on | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div class="mt-2">
          <CRow>
            <CCol md="5">
              <CRow>
                <CCol md="12">
                  <CCard>
                    <CCardBody>
                      <h6 class="text-primary">Interview Panel:</h6>
                      <table width="100%" cellpadding="5">
                        <tbody>
                          <tr v-if="activityDetails.initiated_by_user">
                            <td>
                              {{
                                activityDetails.initiated_by_user.full_name
                                  | convert2Sentence
                              }}
                            </td>
                            <td>
                              {{
                                activityDetails.initiated_by_user.roles | role
                              }}
                            </td>
                          </tr>
                          <tr v-if="activityDetails.return_action_to_user">
                            <td>
                              {{
                                activityDetails.return_action_to_user.full_name
                                  | convert2Sentence
                              }}
                            </td>
                            <td>
                              {{
                                activityDetails.return_action_to_user.roles
                                  | role
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div class="mx-3">
                <CRow class="mb-2 mt-2">
                  <CCol xs="2"><h6>Type</h6> </CCol>
                  <CCol xs="10"
                    ><span class="h6 mx-1">:</span
                    >{{ activityDetails.type_of_process | convert2Sentence }}
                  </CCol>
                </CRow>
                <CRow class="mb-2">
                  <CCol xs="2"><h6>Style</h6> </CCol>
                  <CCol xs="10"
                    ><span class="h6 mx-1">:</span
                    ><span v-html="interviewMode"></span>
                    {{ activityDetails.interview_mode | convert2Sentence }}
                  </CCol>
                </CRow>
                <CRow class="mb-2">
                  <CCol xs="2"><h6>Time</h6> </CCol>
                  <CCol xs="10"
                    ><span class="h6 mx-1">:</span
                    >{{ activityDetails.alloted_time | convert2Sentence }}
                  </CCol>
                </CRow>
              </div>
            </CCol>
            <CCol md="6">
              <div class="align-middle">
                <CCard>
                  <CCardBody>
                    <div>
                      <h6 class="text-primary text-center">
                        Interview Confirmed
                      </h6>
                      <h5 v-if="slots.key" class="text-center">
                        {{ slots.date | formatDate }}
                        {{ slots.start | formatTime }}
                      </h5>
                      <div class="d-flex justify-content-center">
                        <CButton color="primary" @click="openMeet"
                          >Join</CButton
                        >
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import m from "moment";
import _ from "lodash";
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    interviewMode() {
      let icon = "";
      switch (this.activityDetails.interview_mode) {
        case "call":
          icon = '<i class="fas fa-phone-alt mr-1"></i>';
          break;
        case "video":
          icon = '<i class="fas fa-video mr-1 text-primary"></i>';
          break;
        case "in_person":
          icon = '<i class="fas fa-user-alt mr-1 text-success"></i>';
          break;
        default:
          icon = "";
      }
      return icon;
    },
    slots() {
      let slots = {};
      if (this.activityDetails?.selected_slot_date) {
        slots = {
          key: "slected_slot",
          date: this.formatDate(this.activityDetails?.selected_slot_date),
          start: this.convert2Date(
            this.activityDetails?.selected_slot_date,
            this.activityDetails?.selected_slot_start
          ),
          end: this.convert2Date(
            this.activityDetails?.selected_slot_date,
            this.activityDetails?.selected_slot_end
          ),
        };
      }
      return slots;
    },
    interview_link() {
      return this.activityDetails &&
        this.activityDetails?.interview_link &&
        this.activityDetails?.interview_link.indexOf("://") === -1
        ? "http://" + this.activityDetails?.interview_link
        : this.activityDetails?.interview_link;
    },
  },
  methods: {
    convert2Date(date, time) {
      return m(date + " " + time, "YYYY-MM-DD HH:mm:ss");
    },
    formatDate(date) {
      return m(date, "YYYY-MM-DD");
    },
    openMeet() {
      let routeData = `${this.interview_link}`;
      window.open(routeData, "_blank");
    },
  },
  filters: {
    convert2Sentence(data) {
      return _.startCase(data);
    },
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    role(data) {
      if (data) return data.map((e) => _.startCase(e.role_type)).join();
      return "--";
    },
    formatTime(date) {
      if (date) return m(date).format("hh:mm A");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
